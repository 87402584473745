import React from 'react';
import _ from 'lodash';
import { Link } from "gatsby"
import { Carousel as AntCarousel, Row, Col, Pagination } from 'antd';
import { TweenOneGroup } from 'rc-tween-one';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { EdgeImg } from './EdgeImg'
import { getChildrenToRender } from '../utils';

export const isImg = /^http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?/;
const workListUrl = "http://beeplus.com.tw/data/work-data/work-list.json"
// const workListUrl = "http://localhost:8000/data/work-data/work-list.json"
const getWorkUrl = file => `http://www.beeplus.com.tw/data/work-data/${file}`
const TILE_ARRANGEMENT = {
  mobile: {
    col: 2,
    row: 6,
  },
  desktop: {
    col: 5,
    row: 3,
  },
  desktopSmall: {
    col: 4,
    row: 3,
  }
}

const getBlockChildrenFromWorks = (works) => {
  return works.map((work, i) => ({
      name: `block${i}`,
      className: 'block',
      children: {
        wrapper: { className: 'content5-block-content' },
        img: {
          children: getWorkUrl(work.file),
        },
        content: { children: work.name },
      },
  }))
}

class Content5 extends React.PureComponent {
  constructor(props) {
    super(props);
    this.carouselRef = React.createRef();
    this.state = {
      currentPage: 1,
      width: 0,
      height: 0,
      remoteBlockChildren: undefined,
    }
  }

  getChildrenToRender = (data) =>
    data.map((item) => {
      return (
        <Col key={item.name} {...item}>
          <div {...item.children.wrapper}>
              {
                item.children.img.children.match(isImg)
                ? <img src={item.children.img.children} style={{ position: 'absolute', left: 0, top: 0, height: '100%', width: '100%', objectFit: 'cover', objectPosition: 'center' }} alt="img" />
                : <EdgeImg name={item.children.img.children} aspectRatio={1}></EdgeImg>
              }
          </div>
        </Col>
      );
    });

  handlePrevClick = () => {
    this.carouselRef.current.prev()
  }

  handleNextClick = () => {
    this.carouselRef.current.next()
  }

  handlePaginate = (page) => {
    this.setState({
      currentPage: page
    })
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    fetch(workListUrl)
      .then((response) => {return response.json()})
      .then((responseJson) => {
        // console.log(responseJson)
        // console.log(JSON.parse(responseJson))
        // console.log(JSON.parse(responseJson).works)
        const works = responseJson.works
        this.setState({
          remoteBlockChildren: getBlockChildrenFromWorks(works)
        })
      })
      .catch((error) => {
        console.error(error);
      });

    // fetch(workListUrl, {mode: 'no-cors'})
    //   .then((response) => {
    //     // response.json()
    //     console.log(response)
    //   })
    //   // .then((jsonData) => {
    //   //   console.log(jsonData)
    //   // })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  render() {
    const { currentPage, width, remoteBlockChildren } = this.state;
    const { ...props } = this.props;
    const { isMobile, dataSource, usePage, useCarousel, showMore } = props;
    delete props.dataSource;

    const arrangement = isMobile ? TILE_ARRANGEMENT.mobile
                                 : (width < 1140) ? TILE_ARRANGEMENT.desktopSmall
                                    : TILE_ARRANGEMENT.desktop
    const itemsPerSlide = arrangement.row * arrangement.col;
    const blockChildren = remoteBlockChildren || dataSource.block.children
    const childrenToRender = this.getChildrenToRender(
      blockChildren.map(child => ({
        ...child,
        style: {
          ...child.style,
          width: `${100/arrangement.col}%`,
          flex: '0 0 auto',
          float: 'left',
        }
      }))
    );

    const slideSet = _.chunk(childrenToRender, itemsPerSlide).map(group => (
      <Row className={`content5-block-content`} {...dataSource.block}>
        {group}
      </Row>
    ))

    return (
      <div {...props} {...dataSource.wrapper}>
        <div {...dataSource.page}>
          <div key="title" {...dataSource.titleWrapper}>
            {dataSource.titleWrapper.children.map(getChildrenToRender)}
          </div>
          {/* <OverPack
            className={`content-template ${props.className}`}
            {...dataSource.OverPack}
          > */}
            <TweenOneGroup
              component={Row}
              key="ul"
              enter={{
                y: '+=30',
                opacity: 0,
                type: 'from',
                ease: 'easeInOutQuad',
              }}
              leave={{ y: '+=30', opacity: 0, ease: 'easeInOutQuad' }}

            >
            {/* {childrenToRender} */}
            {
              usePage ? (
                <div className="page-wrapper">
                    {
                      slideSet[currentPage - 1]
                    }
                  {/* <div className="carousel-button-prev" onClick={this.handlePrevClick}></div>
                  <div className="carousel-button-next" onClick={this.handleNextClick}></div> */}
                  <div style={{marginTop: '2.5vw'}}>
                  <Pagination
                    simple
                    total={childrenToRender.length}
                    pageSize={itemsPerSlide}
                    onChange={this.handlePaginate}
                    itemRender={(page, type, originalElement) => {
                      if (type === 'prev') {
                        return <a style={{fontSize: '2vw'}}>上一頁</a>;
                      }
                      if (type === 'next') {
                        return <a style={{fontSize: '2vw'}}>下一頁</a>;
                      }
                      return originalElement;
                    }}
                  />
                  </div>
                </div>
              )
              : (
                <div className="carousel-wrapper">
                  <div className="carousel-button-prev" onClick={this.handlePrevClick}></div>
                  <AntCarousel
                    key={`carousel${isMobile ? '-mobile' : ''}`}
                    {...dataSource.carouselProps}
                    infinite={true}
                    autoplay={true}
                    speed={500}
                    autoplaySpeed={5000}
                    infinite={true}
                    dots={true}
                    arrows={true}
                    ref={this.carouselRef}
                    // beforeChange={this.onBeforeChange}
                  >
                    {slideSet}
                  </AntCarousel>
                  <div className="carousel-button-next" onClick={this.handleNextClick}></div>
                </div>
              )
            }
            </TweenOneGroup>
          {/* </OverPack> */}
          {
            !isMobile && showMore &&
            <div
              style={{ marginTop: '4vw' }}>
              <Link
                to="/works"
                style={{
                  color: `black`,
                  textDecoration: `none`,
                  fontSize: '2vw',
                  borderBottom: 'black 2px solid',
                }}
              >READ MORE</Link>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default Content5;
